import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import temilola from '../../assets/images/allied-departments/pharmacy/temilola.jpg';
import jegede from '../../assets/images/allied-departments/pharmacy/jegede.jpg';
import asen from '../../assets/images/allied-departments/pharmacy/asen.jpg';
import fesobi from '../../assets/images/allied-departments/pharmacy/fesobi.jpg';
import adeosun from '../../assets/images/allied-departments/pharmacy/adeosun.jpg';
import daramola from '../../assets/images/allied-departments/pharmacy/daramola.jpg';
import vivian from '../../assets/images/allied-departments/pharmacy/chuka.png';
import team1 from '../../assets/images/team-1-1.jpg';
import ebonodot from '../../assets/images/allied-departments/pharmacy/edo-ebot.jpg';
import edet from '../../assets/images/allied-departments/pharmacy/edet.png';
import olufunke from '../../assets/images/allied-departments/pharmacy/iranloye.jpg';
import hauwa from '../../assets/images/allied-departments/pharmacy/hauwa.jpg';
import bilikisu from '../../assets/images/allied-departments/pharmacy/bilikisu.jpg';
import onyia from '../../assets/images/allied-departments/pharmacy/onyia.jpg';
import ayeni from '../../assets/images/allied-departments/pharmacy/ayeni.jpg';
import thelma from '../../assets/images/allied-departments/pharmacy/thelma.jpg';
import olubunmi from '../../assets/images/allied-departments/pharmacy/olubunmi.jpg';
import abdullateef from '../../assets/images/allied-departments/pharmacy/abdullateef.jpg';
import nurat from '../../assets/images/allied-departments/pharmacy/nurat.jpg';
import esther from '../../assets/images/allied-departments/pharmacy/hunge.jpg';
import idowu from '../../assets/images/allied-departments/pharmacy/idowu.jpg';
import adediji from '../../assets/images/allied-departments/pharmacy/adedeji.jpg';
import rosaline from '../../assets/images/allied-departments/pharmacy/meretighan.jpg';
import abdulsalam from '../../assets/images/allied-departments/pharmacy/abdulsalam.jpg';
import victoria from '../../assets/images/allied-departments/pharmacy/victoria.jpg';
import austin from '../../assets/images/allied-departments/pharmacy/austin.jpg';
import ojebiyi from '../../assets/images/allied-departments/pharmacy/ojebiyi.jpg';
import mabowonku from '../../assets/images/allied-departments/pharmacy/mabawonku.jpg';
import alele from '../../assets/images/allied-departments/pharmacy/janet.jpg';
import udoh from '../../assets/images/allied-departments/pharmacy/udoh.jpg';
import ahefu from '../../assets/images/allied-departments/pharmacy/ahefu.jpg';
import akinsoye from '../../assets/images/allied-departments/pharmacy/akinsoye.jpg';
import kehinde from '../../assets/images/allied-departments/pharmacy/kehinde.jpg';



const Pharmacy = () => {
  const units = [
    {
      name: '	HOD’S OFFICE/SECRETARIAT ',
    },
    {
      name: '	ACCIDENT AND EMERGENCY PHARMACY',
    },
    { name: '	OUT-PATIENT PHARMACY ' },
    {
      name: '	STAFF CLINIC/FAMILYMEDICINE (NHIS) ',
    },

    {
      name: '	GUINNESS EYE CENTRE PHARMACY',
    },
    {
      name: '	CENTRE FOR INTEGTRATED HEALTH PROGRAMME (Formerly APIN Pharmacy',
    },
    { name: '	OLUKOYE RANSOME KUTI CHILDREN EMERGENCY/CHER PHARMACY' },
    {
      name: '	Haematopathology',
    },
    {
      name: '	PHARMACY MAIN STORE ',
    },
    {
      name: '		PHARMACY DRUG REVOLVING FUND',
    },
    {
      name: '		DRUG INFORMATION UNIT/CENTRE',
    },
    {
      name: '		ONCOLOGY PHARMACY',
    },
  ];


  const services = [
    {
      name: 'Pharmacy HOD’s Office/Secretariat',
      value:
        'All managerial and administrative issues are handled here by the Head of Department.',
    },
    {
      name: '',
      value:
        'The office also attends to all official correspondence and enquiries (drug supply ) from Management, Pharmaceutical Companies and others.',
    },
    {
      name: 'Accident and Emergency Pharmacy',
      value:
        'This Unit attends to adult patients brought into the Hospital on emergencies.',
    },
    {
      name: '',
      value:
        'Location: Ground floor Outpatient/Emergency Building. Time of operations: 24 hours everyday',
    },
    {
      name:
        'Out Patient Pharmacy Unit',
      value: 'The Unit provides pharmaceutical care to clients who visit Medical Outpatient Departments (MOPD) for their clinics except the staff clinic and family medicine.',
    },
    {
      name: '',
      value: 'Location: First floor Out-patient Building',
    },
    {
      name: '',
      value: 'Operation Time: 8.00am-4.00pm week days.',
    },
    {
      name: 'Staff Clinic/Family Medicine/NHIS Pharmacy Unit',
      value: 'This unit attends to drugs and other pharmaceutical care needs of patients that are LUTH staff, geriatrics patients and patients who are visiting the Hospital without referrals- General Outpatient (GOP).',
    },
    {
      name:
        '',
      value: 'This unit also attends to medication needs of, primary and secondary enrollees of National Health Insurance Scheme Program',
    },
    {
      name: '',
      value: 'Operation Time: 8.00am-- 4.00pm weekdays ',
    },
    {
      name: 'Guinness Eye Centre Pharmacy Unit',
      value: 'Ophthalmological Eye medications are accessed at the “Guinness Eye Centre”',
    },
    {
      name: '',
      value: 'Operation Time: 8.00am-4.00pm weekdays',
    },
    {
      name: 'Centre For Integrated Health Programmes(CIHP) (formerly APIN Pharmacy)',
      value: 'This unit provides pharmaceutical care to patients on anti retroviral drugs from both out-patient and in-patient ',
    },
    {
      name:
        '',
      value: 'Location: Beside school of Post Basic Ophthalmic nursing, adjacent to Ward C1',
    },
    {
      name: '',
      value: 'Operation time is 8am – 4pm Mondays to Friday',
    },
    {
      name: 'Children Emergency Pharmacy (ORKCEP) ',
      value: 'The unit manages prescriptions emanating from Children Emergency Section of the Hospital. It also receives prescriptions from Paediatric Wards (D-block, Paediatric Oncology Ward, Neonatal unit & E4 wards) from 4pm during weekdays and 24 hours on weekends and public holidays.',
    },
    {
      name: '',
      value: 'This pharmacy unit is unique, in that, it takes care of all formulations that are not commercially available.',
    },
    {
      name: '',
      value: 'Location: Olikoye Ransome-Kuti Children Emergency/CHER Building.',
    },
    {
      name: '',
      value: 'Time of operations: 24 hours everyday',
    },
    {
      name: 'Pharmacy Mainstore',
      value: 'The unit is responsible for receiving of drugs from suppliers, drugs distribution to sub stores, inventory control and store management.  ',
    },
    {
      name:
        '',
      value: 'It operates between 8am – 4pm on week days',
    },
    {
      name: 'Drug Procurement',
      value: 'The unit is responsible for the procurement of drugs through Tender process, local purchase order from duly registered suppliers.',
    },
    {
      name:
        '',
      value: 'Operating time is between 8am -4pm every week days',
    },
    {
      name: 'Drug Information Centre/Unit',
      value: 'The Unit handles all research matters and dissemination of information on new innovations in pharmacy to other health Care Workers.  The Unit is also responsible for organizing trainings, clinical seminars and preparation of drug bulletin. ',
    },
    {
      name: '',
      value: 'Operating time is between 8am -4pm every week days',
    },
    {
      name: 'Oncology Unit',
      value: 'The pharmaceutical services to oncology patients are being managed by pharmacy unit in NSIA-LUTH Cancer Centre',
    },
    {
      name: '',
      value:
        'Time of operation is 8am-4pm',
    },
  ];

  const functions = [
    {
      name:
        '	Provision of quality, effective and cost-effective medicines directly from drug manufacturers or their distributors.  These are well handled to ensure safe, potent, and effective medication are delivered to patients at all times. ',
    },
    {
      name: '	Validating medication orders to ensure safety and efficacy.',
    },
    { name: '	Detecting, preventing and solving drug therapy problems ' },
    {
      name:
        '	Preparing medications (dispensing, compounding and mixing) as appropriate to individual patients and conditions. ',
    },

    {
      name:
        '	Assisting in reporting and documentation of all the potential or real Drug Related problems.',
    },
    {
      name:
        '	Collaborating with other healthcare providers in resolving medication-related issues',
    },
    {
      name:
        '	Provision of pharmaceutical care services to patients on National Health Insurance Scheme Program, Retainers, LUTH schools.  ',
    },
    {
      name:
        '	Provision of Drug information services to patients, healthcare providers and the public ',
    },
    {
      name:
        '	Training of undergraduates on clerkship and IT, interns, students on residency and postgraduate programs.',
    },
  ];

  const rows = services.map((element, ind) => (
    <tr key={ind}>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));

  return (
    <Layout pageTitle='Pharmacy Department | LUTH'>
      <NavOne />
      <PageHeader title='Pharmacy Department' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Pharmacy Department
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        Welcome to the Department of Pharmacy.
                        {/* The Department of Anatomic and Molecular
                        Pathology is one of the foundation departments when
                        Lagos University Teaching Hospital was established. With
                        over 70 staff strength, we have specialist consultants,
                        laboratory scientists, technicians, laboratory
                        assistants and other cadres of staff working as a team
                        to bring about a timely and efficient delivery of
                        quality diagnostic services. We offer excellent
                        pathology training to in-house residents and
                        supernumerary residents from different institutions
                        within the country. */}
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          LIST OF PHARMACY UNITS:
                        </h2>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {units.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                      </p>

                      <p className='course-details__tab-text'>
                        {/* Welcome to the Department of Pharmacy. */}
                        {/* The Department of Anatomic and Molecular
                        Pathology is one of the foundation departments when
                        Lagos University Teaching Hospital was established. With
                        over 70 staff strength, we have specialist consultants,
                        laboratory scientists, technicians, laboratory
                        assistants and other cadres of staff working as a team
                        to bring about a timely and efficient delivery of
                        quality diagnostic services. We offer excellent
                        pathology training to in-house residents and
                        supernumerary residents from different institutions
                        within the country. */}
                        <br />
                        <br />
                        <h3
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          Main Functions
                        </h3>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {functions.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <h2>SERVICES PROVIDED BY EACH UNIT</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                          <th>Units</th>
                          <th>Subspecialties and Services Rendered</th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </Table>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={temilola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PHARM (DR) OLUFOHUNSI TEMILOLA F.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Pharm, M. Pharm, Pharm D, FPCPharm, Dip. Hospt.
                            Mgt.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DIRECTOR, HEAD OF DEPARTMENT (OPD/ HOSPITAL
                            SERVICES)
                            <br />
                            {/* <span className='detail-head'>Specialty:</span> GIT
                            & Liver Pathology, Cytopathology */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={jegede} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>MR. JEGEDE AKINLEKAN</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Pharm, Pharm.D
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (ACCIDENT AND EMERGENCY PHARMACY
                            UNIT)
                            <br />
                            {/* <span className='detail-head'>
                              Area of Research/Focus:
                            </span>{' '}
                            HPV and Cancers */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adeosun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>MR. ADEOSUN OLAJIDE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Pharm. MSc Pharm Chemistry
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR ((Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fesobi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>MR. FESOBI OLUSEYI O.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> MR. DUROJAIYE OLADAPO T.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={daramola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> DARAMOLA ADEFUNMILOLA T. </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Pharm, MSc Clinical Pharmacy
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={vivian} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> CHUKA-EBENE VIVIAN O. </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. MSc Clin.Pharm. MSc Pharmacology, FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={asen} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ASEN T A.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ADEOYE OLUGBENGA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            DEPUTY DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ebonodot} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> EDO-OBOT OLUWATOYIN T.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. MSc. Public Health
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={edet} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> EDET RUBY</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm, FPCPharm
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olufunke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> IRANLOYE OLUFUNKE R.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. MSc Clin. Pharm, FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={hauwa} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ADAMU-ABDULKADIR HAUWA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. MSc Public Health, FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={bilikisu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AMUDA-OLADEJI BILIKISU M.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. PharmD, FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ARINZE MAUREEN N</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. FPCPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={onyia} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ONYIA OLUWATOBI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm. MSc Public Health
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ayeni} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AYENI UWANA A.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            ASSISTANT DIRECTOR (Pharmacy)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={thelma} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AIGBOGUN THELMA I.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CHIEF PHARMACIST
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olubunmi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> IGBIGBI OLUBUNMI O</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CHIEF PHARMACIST
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adediji} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ADEDIJI OLUWASEGUN A.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            PHARMACIST 1
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={nurat} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> BELLO NURAT BISOLA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            PHARMACIST
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={abdullateef} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ABDUL-LATEEF T</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            PHARMACIST 1
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={idowu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> IDOWU OMOBOLA M</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            BPharm.
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CHIEF PHARMACY TECHNICIAN
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={esther} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> HUNGE ESTHER O</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            NATIONAL DIPLOMA (ND)
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CHIEF PHARMACY TECHNICIAN
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={rosaline} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> MERETIGHAN ROSELINE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc Secretarial Administration
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CHIEF CONFIDENTIAL SECRETARY
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={abdulsalam} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ABDULSALAM O.</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            NATIONAL DIPLOMA (ND)
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            PRINCIPAL PHARMACY TECHNICIAN
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AUDU-LAWAL . FASILAT </h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            NATIONAL DIPLOMA (ND)
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            PRINCIPAL PHARMACY TECHNICIAN
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={victoria} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> NLEBEM VICTORIA  C. </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            OND Public Administration
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            SENIOR EXECUTIVE OFFICER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> OKOH MICHAEL M </h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            NATIONAL DIPLOMA (ND)
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            HIGHER EXECUTIVE OFFICER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={austin} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AUSTIN-SIMON F. S </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            HND. Marketing
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            HIGHER STORES OFFICER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ojebiyi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> OJEBIYI SUNDAY E.</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            HND. Marketing
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CHIEF CLERICAL OFFICER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={mabowonku} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> MABAWONKU TOYIN C.</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            SSCE, BSc in view
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            SENIOR CLERICAL OFFICER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={alele} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> ALELE JANET A</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            SSCE
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            SENIOR DATA PROCESSING ASSISTANT 1
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={udoh} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> UDOH J A.</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            SSCE
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            SENIOR DATA PROCESSING OFFICER 1
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ahefu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AHEFU S</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            SSCE
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            CLERICAL OFFICER 1
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinsoye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> AKINSOYOYE O.</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            SSCE
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            HEAD PORTER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> SAMUEL U. E.</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            SSCE
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            HEAD PORTER
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={kehinde} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> FATIMEHIN KEHINDE E.</h3>
                          <p>
                            {/* <span className='detail-head'>Qualification:</span>{' '}
                            SSCE
                            <br /> */}
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            PORTER
                          </p>
                        </div>
                      </div>
                      



                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:ampa@luth.gov.ng'>pharmacy@luth.gov.ng</a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Pharmacy;
